<template>
    <div class="l-agreement container">
        <div class="row">
            <div class="l-agreement__container col">
                <div class="line">   
                    <h2 class="l-agreement__container-title">
                        СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2> 
                    <div class="l-agreement__container-info">
                        <p>Настоящим Я, в соответствии с требованиями Федерального закона от 27.07.09 №152-ФЗ «О персональных данных» даю ООО ПКО «Юрисконсульт», находящемуся по адресу {{ adress }}, согласие на обработку моих персональных, в том числе с использованием средств автоматизации.</p>
                        <p>Согласие относится к обработке следующих персональных данных: фамилия, имя, отчество; номера контактного телефона; контактный адрес электронной почты (e-mail); данные для заключения и исполнения договоров, в том числе, путем осуществления со мной прямых контактов и рассылок по различным средствам связи.</p>
                        <p>Согласие дано на неопределенный срок, но не более срока, необходимого для достижения целей обработки персональных данных и/или отзыва настоящего согласия. С условиями отзыва согласия, посредством направления письменного уведомления по указанным в настоящим согласии адресам, ознакомлен.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AgreementView',

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Согласие на обработку персональных данных',
            // all titles will be injected into this template
            // titleTemplate: '%s | Юрисконсульт',
        },

        data() {
            return {
                number: '8-930-412-00-00',
                email: 'oooyuriskonsult@mail.ru',
                adress: '394055, г. Воронеж, ул. Депутатская, дом 11А, помещение Х, офис 8',
            };
        },
    }
</script>

<style scoped>
.l-agreement__container-title {
    font-family: "Roboto Bold";
    font-size: 20px; 
    text-align: left;
  }

  .l-agreement__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

   .l-agreement::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/close-up-of-workers-with-briefcases 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>