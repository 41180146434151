<template>
    <div class="container">
        <div class="row">
            <div class="l-law-ser__container col">
                <div class="line">
                    <h2 class="l-law-ser__container-title">
                        Юридические услуги
                    </h2>
                    <div class="l-law-ser__container-info">
                        <div class="line">
                            <div>
                                УСЛУГИ ЮРИДИЧЕСКОГО СОПРОВОЖДЕНИЯ В АРБИТРАЖНОМ СУДЕ
                                <div>
                                    Чтобы эффективно и оперативно решить споры, возникшие в процессе ведения бизнеса, необходима квалифицированная юридическая помощь. Специалисты ООО ПКО «Юрисконсульт» помогут урегулировать разногласия, возникающие в сфере предпринимательской и иной экономической деятельности, а также защитят ваши интересы в суде. Мы готовы предоставить вам квалифицированные услуги независимо от того, являетесь вы истцом или ответчиком.
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <div>
                                МЫ ОКАЗЫВАЕМ УСЛУГИ ПО СЛЕДУЮЩИМ КАТЕГОРИЯМ ДЕЛ:
                                <div>
                                    <ol>•	Взыскание задолженности по любым видам договоров;</ol>
                                    <ol>•	Неисполнение или ненадлежащего исполнение обязательств;</ol>
                                    <ol>•	Признания несостоятельности юрлица или физлица;</ol>
                                    <ol>•	Изменения условий или расторжения договоров, а также признания сделки недействительной;</ol>
                                    <ol>•	Иные дела по запросу.</ol>
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <div>
                                УСЛУГИ ПО АРБИТРАЖНЫМ СПОРАМ ВКЛЮЧАЮТ:
                                <div>
                                    <ol>•	Устные и письменные консультации.</ol>
                                    <ol>•	Разработку правовой позиции.</ol>
                                    <ol>•	Содействие в формировании доказательственной базы.</ol>
                                    <ol>•	Работу по досудебному урегулированию споров (переговоры с контрагентами клиента, претензионная деятельность).</ol>
                                    <ol>•	Подготовку и подачу правовых документов в арбитражный суд (правовая экспертиза учредительных и иных документов, предоставленных клиентом, составление исков, отзывов и т. д.).</ol>
                                    <ol>•	Ведение дел в судах любых уровней.</ol>
                                    <ol>•	Оспаривание решений суда в вышестоящих инстанциях.</ol>
                                    <ol>•	Сопровождение исполнения решений.</ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'LawServiceView',

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Юридические услуги',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'Услуги юридического сопровождения в арбитражном суде',
      };
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },
  }
</script>

<style scoped>
  .l-law-ser__container .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-law-ser__container .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }

  .l-law-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px; 
    text-align: left;
  }

  .l-law-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

  .l-law-ser__container .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/close-up-of-workers-with-briefcases 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (min-width: 992px) {
    .l-law-ser__container-title {
        font-size: 50px; 
    }

    .l-law-ser__container-info {
        font-size: 22px;
    }
  }
</style>