<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },

  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Юрисконсульт',
      // all titles will be injected into this template
      titleTemplate: '%s | Юрисконсульт',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Банкротство физических и юридических лиц, юридические услуги в сфере банкротства, освобождение от долгов граждан, сопровождение процедуры банкротства. Взыскание просроченной задолженности, работа по взысканию на судебной и досудебной стадии.',
      }],
      
      link: [
      { rel: 'icon', href: '/logo.ico' }
      ]
  }
}
</script>

<style src="./css/style.css" />
