<template>
  <div class="l-service" >
    <div class="container">
      <div class="row">
        <div class="l-service__container col">
          <div class="l-service__container-content">
          <IndividualBankruptcySer class="line" />
          <LegalBankruptcySer class="line" />
          <LawService class="line" />
          <DebtsSer class="line" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DebtsSer from '@/components/ServiceView/DebtsSer.vue';
  import IndividualBankruptcySer from '@/components/ServiceView/IndividualBankruptcySer.vue';
  import LegalBankruptcySer from '@/components/ServiceView/LegalBankruptcySer.vue';
  import LawService from '@/components/ServiceView/LawService.vue';

  export default {
    name: 'ServiceView',
    components: {
    DebtsSer,
    IndividualBankruptcySer,
    LegalBankruptcySer,
    LawService,
    },

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Услуги',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'Услуги компании: банкротство физических и юридических лиц, юридические услуги, взыскание просроченной задолженности.'
      }
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },

  }
</script>

<style scoped>
  .l-service .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-service .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }

  .l-service {
    position: relative;
  }

  .l-service .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  .l-service::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/businesswoman-in-despair-because-of-crisis 1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>