<template>
    <div class="container">
        <div class="row">
            <div class="l-debts-ser__container col">
                <div class="line">
                    <h2 class="l-debts-ser__container-title">
                        Взыскание просроченной задолженности
                    </h2>
                    <div class="l-debts-ser__container-info">
                        <div class="line">
                          <div>
                            Для должников
                            <div>
                              ООО ПКО «Юрисконсульт» с уважением относится ко всем своим клиентам, в том числе должникам. Наша цель- договориться о платежах на взаимовыгодной основе. ООО ПКО «Юрисконсульт» использует исключительно правовые методы и рассматривает все возможные варианты выхода должников из сложившейся ситуации:
                              <ol>- дисконт при полной оплате суммы долга, рассрочки оплаты долга, </ol>
                              <ol>-заключение мирового соглашения с дисконтом до 50% , т.д. (Не является публичной офертой. Условия дисконтов и мировых соглашений обговариваются с должниками индивидуально.)</ol>
                            </div>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Мы относимся к должникам с уважение и выполняем свои обещания, ожидая того же взамен от должников. Считаем, что любой вопрос можно решить путем переговоров. Приглашаем Вас к сотрудничеству!
                          </div>
                          <!-- <div>
                            Реквизиты для погашения задолженности:
                            При возникновении любых вопросов а также для ваших предложений вы можете воспользоваться формой обратной связи, или позвонить нам по телефону {{ number }}, а также написать на электронную. почту
                          </div> -->
                        </div>
                        <!-- УБРАТЬ АБЗАЦ <div class="line">
                          <div>
                            Для кредиторов
                            <div>
                              Основным направлением работы ООО ПКО «Юрисконсульт» является приобретение на основании договора цессии (уступки прав требования) прав требований, возникших из кредитных обязательств как физических, так и юридических лиц.
                            </div>
                            <div>
                              ООО ПКО «Юрисконсульт» оказывает услуги:
                              <ol>- по возврату просроченной задолженности банкам, юридическим и физическим лицам, на основании агентского договора;</ol>
                              <ol>- по выкупу долгов, на основании договора цессии (уступки прав требования).</ol>
                            </div>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            ООО ПКО «Юрисконсульт»  осуществляет взыскание просроченной задолженности на досудебном, судебном этапах, а также на этапе исполнительного производства.
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            ООО ПКО «Юрисконсульт»  осуществляет свою деятельность с соблюдением требований норм ФЗ на основании Свидетельства № 5/18/36000-КЛ от 14 марта 2018 года .
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Наши цены и условия сотрудничества Вас приятно удивят!
                            Также рассмотрим Ваши предложения по продаже принадлежащих нам портфелей задолженностей.
                          </div>
                          -- <div>
                            Более подробно о каждой нашей услуге Вы можете узнать, позвонив нам по телефону {{ number }} , или приехав в наш офис по адресу {{ number }}.
                          </div> --
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'DebtsView',

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Взыскание просроченной задолженности',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'ООО ПКО «Юрисконсульт» является коллекторским агентством и с уважением относится ко всем своим клиентам, в том числе должникам. Наша цель - договориться о платежах на взаимовыгодной основе.',
      };
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },
  }
</script>

<style scoped>
  .l-debts-ser__container .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-debts-ser__container .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }


 .l-debts-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px; 
    text-align: left;
  }

  .l-debts-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

  .l-debts-ser__container .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/взыскание 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (min-width: 992px) {
    .l-debts-ser__container-title {
      font-size: 50px; 
    }

    .l-debts-ser__container-info {
      font-size: 22px;
    }
  }
</style>