export default {
    computed: {
        isMobile() {
            return ( ( window.innerWidth < 768 ) );
        },

        isTablet() {
            return ( ( window.innerWidth < 992 ) && ( window.innerWidth >= 768 ) );
        },

        isDesktop() {
            return ( ( window.innerWidth >= 992 ) );
        },
    },
}