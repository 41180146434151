<template>
    <div class="container">
        <div class="row">
            <div class="l-legal-ser__container col">
                <div class="line">
                    <h2 class="l-legal-ser__container-title">
                        Банкротство юридических лиц
                    </h2>
                    <div class="l-legal-ser__container-info">
                        <div class="line">
                          <div>
                          в ООО ПКО «Юрисконсульт» реализуется посредством комплекса мероприятий по защите правовых и материальных интересов должника от ответственности, одновременной минимизации убытков и сохранению деловой репутации. Преимущества комплексного подхода выражаются в следующих аспектах:
                          <ol>•	сохранение прав клиента на максимум активов;</ol>
                          <ol>•	принятие мер по оперативному погашению дебиторской задолженности;</ol>
                          <ol>•	реструктуризация управления;</ol>
                          <ol>•	жесткий мониторинг финансовых потоков.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Услуги кредиторам:
                            <ol>•	подготовка и подача в арбитражный суд заявления о признании должника несостоятельным (банкротом);</ol>
                            <ol>•	подготовка и подача в арбитражный суд требования кредитора о включении его требования в реестр требований кредиторов должника;</ol>
                            <ol>•	разработка дорожной карты в рамках процедуры банкротства должника в зависимости от статуса кредитора, размера его требований по отношению ко всей совокупности требований кредиторов должника;</ol>
                            <ol>•	выявление сделок должника, которые могут быть оспорены по основаниям, предусмотренным действующим законодательством, в том числе Федеральным законом «О несостоятельности (банкротстве)», подготовка проектов исковых заявлений, направление требований арбитражным управляющим о необходимости оспаривания сделок;</ol>
                            <ol>•	проведение мероприятий, связанных с привлечением к субсидиарной ответственности контролирующих должника лиц (руководителя, учредителей и др.);</ol>
                            <ol>•	проведение мероприятий, связанных с реализацией предмета залога по наиболее выгодной для кредитора стоимости, подготовка положения о его реализации, при необходимости проведение мероприятий по оставлению залоговым кредитором предмета залога за собой, в том числе подготовка и направление конкурсному управляющему соответствующего заявления;</ol>
                            <ol>•	сбор и систематизация информации о должниках и их финансовом состоянии;</ol>
                            <ol>•	проведение мероприятий, связанных с взысканием дебиторской задолженности в судебном порядке, в том числе подготовка и подача исковых заявлений, представительство в суде, сопровождение исполнительного производства;</ol>
                            <ol>•	проведение мероприятий, направленных на досудебное погашение задолженности, в том числе представление интересов кредитора в переговорах с должниками, подготовка и направление требований о погашении задолженности;</ol>
                            <ol>•	юридическое сопровождение сделок по уступке права требования, заключения мировых соглашений;</ol>
                            <ol>•	организация и координация проведения комплексной оценки проблемных должников;</ol>
                            <ol>•	анализ предложений об отступном, предоставление рекомендаций;</ol>
                            <ol>•	иные юридические услуги, необходимые для обеспечения интересов клиента как кредитора.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Услуги должникам:
                            <ol>•	представление интересов клиента в переговорах с кредитором, в том числе по вопросам реструктуризации задолженности, заключению мирового соглашения;</ol>
                            <ol>•	юридическое сопровождение сделок по переводу долга / уступке права требования;</ol>
                            <ol>•	проведение мероприятий, связанных с недопущением взыскания задолженности в судебном порядке, в том числе представительство в суде, оспаривание сделок;</ol>
                            <ol>•	разработка мероприятий по предотвращению банкротства;</ol>
                            <ol>•	проведение мероприятий, связанных с привлечением к субсидиарной ответственности контролирующих должника лиц (руководителя, учредителей и др.);</ol>
                            <ol>•	анализ имущества и финансового состояния должника для определения стратегии банкротства;</ol>
                            <ol>•	подготовка и подача в арбитражный суд заявления должника о признании его несостоятельным (банкротом);</ol>
                            <ol>•	иные юридические услуги, необходимые для обеспечения интересов клиента как должника.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Услуги должникам/ кредиторам:
                            <ol>•	консультирование по вопросам реструктуризации задолженности, ликвидации и банкротства;</ol>
                            <ol>•	обжалование действий/бездействия арбитражных управляющих в судах, федеральном органе исполнительной власти, осуществляющим контроль за деятельностью арбитражных управляющих, и/или саморегулируемых организациях арбитражных управляющих;</ol>
                            <ol>•	юридическое сопровождение сделки/сделок по приобретению кредиторской задолженности должника и/или продаже дебиторской задолженности;</ol>
                            <ol>•	представление интересов клиента в судебных заседаниях по делу о банкротстве должника;</ol>
                            <ol>•	оспаривание в судебном порядке необоснованных и/ или спорных требований к должнику;</ol>
                            <ol>•	подготовка и подача в арбитражный суд заявлений о включении в реестр требований кредиторов;</ol>
                            <ol>•	участие в собраниях кредиторов должника (комитете кредиторов);</ol>
                            <ol>•	организация и проведение собраний кредиторов должника в случае уклонения арбитражных управляющих от их проведения;</ol>
                            <ol>•	юридическое сопровождение процедуры конкурсного производства, в том числе:</ol>
                            <ol>•	разработка положения о порядке, сроках и условиях продажи имущества должника;</ol>
                            <ol>•	разработка и согласование положения о порядке, сроках и условиях продажи залогового имущества должника у залогодержателя;</ol>
                            <ol>•	организация продажи имущества должника на торгах;</ol>
                            <ol>•	разработка стратегии и плана работы по приобретению кредиторской задолженности для получения большинства голосов на собрании кредиторов должника.</ol>
                            <ol>•	представление интересов клиента при проведении торгов по продаже имущества должника;</ol>
                            <ol>•	оспаривание в судебном порядке результатов торгов по продаже имущества должника;</ol>
                            <ol>•	подбор и согласование с клиентом кандидатуры арбитражного управляющего;</ol>
                            <ol>•	юридическое сопровождение деятельности арбитражных управляющих;</ol>
                            <ol>•	услуги по подготовке, согласованию и подписанию мирового соглашения.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Стоимость сопровождения банкротства
                            Затраты на сотрудничество с ООО ПКО «Юрисконсульт» по обслуживанию банкротства складываются из оплаты услуг арбитражного управляющего и команды экспертов по бухгалтерии, налогообложению, юриспруденции. На итоговую цену влияет специфика ситуации – преднамеренное ли банкротство, объем обрабатываемой документации, перспективы удовлетворения кредиторов и ликвидность активов.
                            Привлечение узкопрофильных специалистов по оценке, торгам и прочим аспектам оплачивается сверх согласованного тарифа.
                            Стоимость дополнительных услуг обсуждается с клиентом отдельно.
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'LegalView',

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Банкротство юридических лиц',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'В компании работают юристы и взыскатели – профессионалы своего дела, готовые на выгодных для Вас условиях оказать качественную и своевременную помощь. Выгодные условия и индивидуальный подход для каждого клиента. Доверьте дело профессионалам.',
      };
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },
  }
</script>

<style scoped>
  .l-legal-ser__container .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-legal-ser__container .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }


  .l-legal-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px; 
    text-align: left;
  }

  .l-legal-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

  .l-legal-ser__container .line {
    padding-left: 20px;
    padding-right: 20px;
  }

   .container::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/businesswoman-in-despair-because-of-crisis 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (min-width: 992px) {
    .l-legal-ser__container-title {
      font-size: 50px; 
    }

    .l-legal-ser__container-info {
      font-size: 22px;
    }
  }
</style>