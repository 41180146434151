<template>
  <div class="l-service-info__container">
    <h2 class="l-service-info__container-title">
      <a href="/service">Услуги</a>
    </h2>
    <div class="l-service-info__container-info">
      <ol><a href="/service/bankrotstvo-fizicheskih-lic">- банкротство физических лиц;</a></ol>
      <ol><a href="/service/bankrotstvo-yuridicheskih-lic">- банкротство юридических лиц;</a></ol>
      <ol><a href="/service/yuridicheskie-uslugi">- юридические услуги;</a></ol>
      <ol><a href="/service/vzyskanie-prosrochennoj-zadolzhennosti">- взыскание просроченной задолженности;</a></ol>
      <ol><a href="/service/realizaciya-imushchestva">- реализация имущества.</a></ol>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServiceInfo',
  }
</script>

<style scoped>
  .l-service-info__container {
    padding: 20px;
  }

  .l-service-info__container-title {
    font-family: "Roboto Bold";
    font-size: 24px;
    text-align: left;
    
  }

  .l-service-info__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
  }

  .l-service-info__container-info a {
      /* text-decoration: none; */
      color: black;
  }

  .l-service-info__container-info a:hover {
      /* text-decoration: none; */
      color: #85634cce;
      /* font-family: "Roboto Bold"; */
  }

  .l-service-info__container-info a:active {
    opacity: 0.7;
  }
  

  a {
    text-decoration: none;
    color: inherit;
    width: 100%;
  }

  @media (min-width: 992px) {
    .l-service-info__container-title {
      font-family: "Roboto Condensed";
      font-size: 45px;
      display: flex;
      width: min-content;
      /* background: #393A3D; */
      background: linear-gradient(269.91deg, #FEA41E 0%, #E6B567 0.01%, #6E4930 56.25%);
      color: white;
      min-width: 320px;
      padding-left: 30px;
      line-height: 60px;
    }

    .l-service-info__container-info {
      font-size: 36px;
      padding-top: 20px;
      line-height: 42px;
    }

    
    

  }
</style>