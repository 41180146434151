<template>
    <div class="dropdown-menu-item" :class="{subitem: isSubitem, urt: item.ur == 'Второй'}">
        <dropdown-menu v-if="item.items" direction="left" :mode="mode">
                <a :href="item.url" slot="trigger" @click="triggerClickHandler">{{ item.title }}</a>
            <ul slot="body">
                <a :href="item.url" v-if="!isDesktop">{{ item.title }}</a>
                <li v-for="(subitem, i) in item.items" :key="`subitem-${i}`">
                    <dropdown-menu-item isSubitem :item="subitem" />
                </li>
            </ul>
        </dropdown-menu>
        <a v-else :href="item.url"  @click="$emit('menuClickHandlers', item.title)" :class="{active: activeMenu == item.title}">{{ item.title }}</a>
    </div>
</template>

<script>
  import DropdownMenu from 'v-dropdown-menu'
  import 'v-dropdown-menu/dist/v-dropdown-menu.css'

  export default {
    name: 'DropdownMenuItem',

    components: {
      DropdownMenu,
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        isSubitem: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'hover',
        },
        activeMenu: {
            type: String,
            default: 'Главная',
        },
    },

    methods: {
      triggerClickHandler(ev) {
        if (!this.isDesktop) {
          ev.preventDefault();
        }
      },
    }
  }
</script>

<style>
  .dropdown-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    /* background: #393A3D; */
    
  }

  .dropdown-menu-item .v-dropdown-menu {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .dropdown-menu-item-first {
    /* width: 150px;
    height: 40px; */
  }

 .v-dropdown-menu__trigger {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .dropdown-menu-item .dropdown-menu-item-first a {
    /* width: 150px; */
    /* color: red; */
  }

  .dropdown-menu-item .v-dropdown-menu__container {
    min-width: 300px;
    overflow: visible;
  }

  .dropdown-menu-item.subitem .v-dropdown-menu__container {
    /* left: 318px; */
    /* top: -10px; */
    min-width: 420px;
  } 

  .dropdown-menu-item a {
    /* font-weight: bold; */
    /* line-height: 45px; */
    text-decoration: none;
    cursor: pointer;
    color: white;
    padding: 0 5px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 45px;
    font-family: 'Roboto Condensed';
  }

  .dropdown-menu-item .v-dropdown-menu--active a,
  .dropdown-menu-item a:hover {
    opacity: 0.9;
    color: #6E4930;
  }

  .dropdown-menu-item a:active {
    opacity: 0.7;
  }

  .dropdown-menu-item:hover,
  .v-dropdown-menu--active {
    background: white;
  }

  .dropdown-menu-item.subitem {
    /* background: #DFDFDF; */
    background: #6E4930;
    
  }

  .dropdown-menu-item.subitem a {
    color: white;
    font-size: 20px;
    line-height: 36px;
  }

    .dropdown-menu-item.subitem:hover {
    background: white;
  }
  
  .dropdown-menu-item.subitem:hover a  {
    color:#6E4930;
    /* color: red; */
  } 

    .dropdown-menu-item.subitem.urt:hover {
    background: white;
  }

      .dropdown-menu-item.subitem.urt a {
    color: white;
    font-size: 24px;
  }

  .dropdown-menu-item.subitem.urt:hover a  {
    color:#6E4930;
    /* color: red; */
  } 

  @media (min-width: 992px) {
    .dropdown-menu-item .v-dropdown-menu__container {
      min-width: 500px;
      overflow: visible;
    }

    .dropdown-menu-item.subitem a {
    font-size: 36px;
    line-height: 36px;
    }
  }
</style>