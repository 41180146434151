<template>
    <div class="container">
        <div class="row">
            <div class="l-individual-view__container col">
                <div class="line">
                    <h2 class="l-individual-view__container-title">
                        Банкротство физических лиц
                    </h2>
                    <div class="l-individual-view__container-info">
                        <div class="line">
                          <div>
                            Законодательство Российской Федерации, регламентирующее несостоятельность (банкротство), находится в постоянном развитии и совершенствовании. Законом, регулирующим в настоящее время вопросы несостоятельности (банкротства), является Федеральный закон РФ от 26.10.2002 «О несостоятельности (банкротстве)» (далее - Закон о банкротстве).
                          </div>
                          <div>
                            Закон о банкротстве устанавливает основания, порядок и последствия признания арбитражным судом гражданина несостоятельным (банкротом), очерёдность удовлетворения требований кредиторов, порядок применения процедур в деле о несостоятельности (банкротстве) гражданина.
                          </div>
                          <div>
                            Инициировать процедуру банкротства физического лица может:
                            <ol>•	гражданин;</ol>
                            <ol>•	конкурсный кредитор;</ol>
                            <ol>•	уполномоченный орган.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Основные требования для введения процедуры банкротства физического лица:
                            <ol>•	наличие долга по денежным обязательствам и (или) обязанности по уплате обязательных платежей на сумму не менее 500 000 рублей;</ol>
                            <ol>•	неисполнение указанных обязательств более 3 месяцев;</ol>
                            <ol>•	наличие объективных признаков неплатёжеспособности и (или) признаков недостаточности имущества.</ol>
                          </div>
                          <div>
                            Если вы отвечаете данным условиям, мы можете обратиться к нам по телефону {{ number }} за бесплатной консультацией по наиболее быстрому и выгодному для Вас проведению процедуры банкротства, что особенно важно при наличии у Вас имущества, в том числе находящегося в залоге у банков.
                          </div>
                          <div>
                            Исчерпывающий перечень требований и необходимых документов указан в ст. 213.4, ст. 213.5 Закона о банкротстве. Данные документы Вам оперативно и квалифицированно помогут подготовить наши юристы.
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Сведения о введении в отношении вас соответствующей процедуры, а также о документах и публикациях вы можете получить на сайте Единого Федерального реестра сведений о банкротстве.
                          </div>
                          <div>
                            При процедуре банкротства рассматриваются три варианта исхода дела:
                            <ol>•	заключение мирового соглашения с кредиторами;</ol>
                            <ol>•	реструктуризация долгов;</ol>
                            <ol>•	продажа имущества.</ol>
                          </div>
                          <div>
                            По итогам рассмотрения дела о банкротстве физического лица в случаях, предусмотренных Законом о банкротстве, арбитражный суд может отказать в применении последствий в виде освобождения гражданина от исполнения обязательств, поэтому необходимо воспользоваться помощью профессионалов, чтобы освобождение от долгов все-таки произошло.
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Основные расходы при судебном банкротстве состоят из:
                            <ol>•	оплата публикаций на сайте Единого федерального реестра сведений о банкротстве (ЕФРСБ);</ol>
                            <ol>•	почтовые расходы для обмена документами с кредиторами и госорганами;</ol>
                            <ol>•	публикация в газете «Коммерсантъ» сведений о реструктуризации долга или продаже имущества;</ol>
                            <ol>•	вознаграждение финансовому управляющему.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Почему стоит обратиться к нам?
                            <div>
                              Во-первых, для прохождения процедуры судебного банкротства требуется участие арбитражного финансового управляющего. В отличие от других компаний, Вам не придется искать арбитражного управляющего самому. Стоимость услуг арбитражного управляющего включена в стоимость услуг наших юристов.
                            </div>
                            <div>
                              Во-вторых, мы не обещаем 100% избавление от всех долгов за счет процедуры банкротства, так как знаем и сообщаем нашим клиентам уже при первой консультации, какие именно долги согласно статьи 213.28 Федерального закона о банкротстве не списываются. Также мы не обещаем «вывод имущества» из процедуры банкротства, так как это обещание не всегда соответствует закону, но при наличии такой возможности в соответствии с действующим законодательством сделаем все возможное для сохранения ваших активов и в процедуре банкротства.
                            </div>
                            <div>
                              В-третьих, мы до вступления в дело, индивидуально подходим к каждому обратившемуся к нам клиенту и обсуждаем стоимость всех обязательных платежей и своего вознаграждения в том числе. У нас все прозрачно, и поэтому в ходе процедуры банкротства не возникает проблем по «всплывающим из ниоткуда» дополнительным оплатам. Также мы предусматриваем дисконт при полной оплате наших услуг и рассрочку оплаты наших услуг, обсуждаемые индивидуально, в зависимости от сложности случая.
                            </div>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            С 01 сентября 2020 года появилась возможность пройти упрощенное банкротство
                            <div>Без суда и судебных расходов</div>
                            <div>Дешевле обычной процедуры</div>
                            <div>Простота в подготовке и сборе документов</div>
                          </div>
                          <div>По всем вопросам обращаться по телефону: {{ number }}</div>
                        </div>
                        <div class="line">
                          <div class="text-center">
                            <table>
                              <caption>
                                Отличия судебной и внесудебной процедур банкротства
                              </caption>
                              <tbody>
                                <tr class="table-first">
                                  <td>Условия</td>
                                  <td>Через суд</td>
                                  <td>Через МФЦ без суда</td>
                                </tr>
                                <tr>
                                  <td class="table-first">Куда обращаться</td>
                                  <td>Арбитражный суд</td>
                                  <td>Многофункциональный центр</td>
                                </tr>
                                <tr>
                                  <td class="table-first">Расходы должника</td>
                                  <td>300 руб. - госпошлина, 25 000 руб. - вознаграждение арбитражного управляющего, а также дополнительные расходы</td>
                                  <td>Бесплатно</td>
                                </tr>
                                <tr>
                                  <td class="table-first">Ограничение по сумме долгов</td>
                                  <td>Для подачи заявления должником ограничений по сумму долгов нет</td>
                                  <td>Общий размер - от 50 000 <br>до 500 000 руб.</td>
                                </tr>
                                <tr>
                                  <td class="table-first">Постановление об окончинии исполнительного производства</td>
                                  <td>Не требуется</td>
                                  <td>Требуется</td>
                                </tr>
                                <tr>
                                  <td class="table-first">Длительность</td>
                                  <td>От нескольких месяцев до нескольких лет</td>
                                  <td>Ровно 6 месяцев</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!-- УБРАТЬ АБЗАЦ <div class="line">
                            <div>
                            Заявление нужно подать в письменном виде в МФЦ по месту жительства или пребывания. К нему необходимо приложить список всех известных должнику кредиторов по установленной форме.
                            Внимание! Кредиторов или обязательств, которые не указаны в списке, процедура внесудебного банкротства не коснётся — от таких долгов человек не освобождается. Также важно правильно указывать корректные сведения о налогах и сборах, точные суммы долгов.
                            МФЦ проверит соответствие заявителя установленным критериям и в течение 3 рабочих дней включит его в Единый федеральный реестр сведений о банкротстве. С этого момента начинается процедура внесудебного банкротства.
                          </div>
                        </div> -->
                        <div class="line">
                          <div>
                            Пока идёт внесудебное банкротство, может быть инициировано судебное. Это право есть у кредиторов в следующих случаях:
                            <ol>•	кредитор не указан в списке, прилагавшемся к заявлению о внесудебном банкротстве;</ol>
                            <ol>•	задолженность указана, но занижена;</ol>
                            <ol>•	обнаружено имущество или имущественные права должника;</ol>
                            <ol>•	суд признал сделку должника недействительной по иску кредитора;</ol>
                            <ol>•	должник не направил в МФЦ сведения об улучшении имущественного положения.</ol>
                          </div>
                          <div>
                            Если в течение срока процедуры внесудебного банкротства начнётся судебное, внесудебная процедура прекращается - МФЦ фиксирует это в Едином федеральном реестре сведений о банкротстве.
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Как завершается внесудебное банкротство
                            Через 6 месяцев МФЦ включает в Единый федеральный реестр сведений о банкротстве информацию о завершении процедуры. С этого момента гражданин освобождается от обязательств перед кредиторами в размере суммы, указанной в заявлении. То есть по этим долгам будет невозможно принудительное взыскание.
                          </div>
                        </div>
                        <!-- УБРАТЬ АБЗАЦ  <div class="line">
                          <div>
                            От каких долгов не избавит внесудебное банкротство
                            Внесудебное банкротство не позволит освободиться от следующих долгов:
                            <ol>•	не указанных в списке кредиторов, который прилагался к заявлению о внесудебном банкротстве. Не указанные в списке суммы придётся погашать даже после завершения банкротства;</ol>
                            <ol>•	возникших в период процедуры внесудебного банкротства;</ol>
                            <ol>•	по возмещению вреда жизни или здоровью;</ol>
                            <ol>•	по выплате зарплаты и выходного пособия;</ol>
                            <ol>•	по возмещению морального вреда;</ol>
                            <ol>•	по алиментам;</ol>
                            <ol>•	при привлечении к субсидиарной ответственности;</ol>
                            <ol>•	по возмещению убытков, умышленно или неосторожно причинённых юрлицу, участником которого был гражданин;</ol>
                            <ol>•	о возмещении вреда имуществу, причинённого умышленно или по неосторожности.</ol>
                            <ol></ol>
                            <ol></ol>
                          </div>
                        </div> -->
                        <!-- УБРАТЬ АБЗАЦ <div class="line">
                          <div>
                            Также гражданин не освобождается от долгов при любом из следующих обстоятельств:
                            <ol>1. Вступило в силу решение суда о его привлечении к уголовной или административной ответственности за неправомерные действия при банкротстве или фиктивное банкротство.</ol>
                            <ol>2. Доказано, что при возникновении или исполнении обязательств гражданин действовал незаконно: совершил мошенничество, злостно уклонялся от платежей, предоставил кредитору заведомо ложные сведения, скрыл или уничтожил имущество.</ol>
                          </div>
                        </div> -->
                        <div class="line">
                          <div>
                            Какие последствия имеет внесудебное банкротство
                            У внесудебного банкротства есть следующие последствия:
                            <ol>1. В течение 5 лет нельзя брать кредиты и займы без указания на факт банкротства.</ol>
                            <ol>2. В течение 3 лет нельзя занимать должности в органах управления юридического лица.</ol>
                            <ol>3. В течение 10 лет нельзя занимать должности в органах управления кредитной организации.</ol>
                            <ol>4. В течение 5 лет нельзя занимать должности в органах управления страховой организации, негосударственного пенсионного фонда, управляющей компании инвестиционного фонда, паевого инвестиционного фонда и негосударственного пенсионного фонда или микрофинансовой компании.</ol>
                          </div>
                        </div>
                        <div class="line">
                          <div>
                            Если гражданин перестал быть индивидуальным предпринимателем менее чем за один год до подачи заявления о внесудебном банкротстве, то в течение 5 лет после его завершения нельзя:
                            <ol>1. Регистрироваться в качестве ИП.</ol>
                            <ol>2. Осуществлять предпринимательскую деятельность и руководить юрлицом.</ol>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'IndividualView',

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Банкротство физических лиц',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'Выгодное и быстрое проведение процедуры банкротства. Все документы оперативно и квалифицированно помогут подготовить наши юристы. Полное сопровождение процедуры банкротства от начала и до конца. Индивидуальный подход и предоставление отсрочки платежа.',
        number: '8-960-127-50-08',
      };
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },
  }
</script>

<style scoped>
  .l-individual-view__container .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-individual-view__container .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }

  .l-individual-view__container-title {
    font-family: "Roboto Bold";
    font-size: 24px; 
    text-align: left;
  }

  .l-individual-view__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

  .l-individual-view__container .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  .text-center {
    text-align: center;
  }

  /* внешние границы таблицы серого цвета толщиной 1px */
  table {
    border: 1px solid grey;
  }
/* границы ячеек первого ряда таблицы */
  th {
    border: 1px solid grey;
  }
/* границы ячеек тела таблицы */
  td {
    border: 1px solid grey;
    padding: 7px 0px;
    vertical-align: middle;
  }

  caption {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .table-first {
    font-weight: bold;
  }

  .container::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/young-couple-checking-their-family-budget 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

   @media (min-width: 992px) {
    .l-individual-view__container-title {
      font-size: 50px; 
    }

    .l-individual-view__container-info {
      font-size: 22px;
    }
   }
</style>