<template>
  <div class="l-about container">
    <div class="row">
      <div class="l-about__container col">
        <div class="line">
          <h2 class="l-about__container-title">
            О компании
          </h2>  
          <div class="l-about__container-content">
            <div class="l-about__container-content-text">
              ООО ПКО «Юрисконсульт» внесено в реестр операторов персональных данных 02 февраля 2018 года, регистрационный номер 36-18-002235. Информация об этом доступна на официальном сайте Федеральной службы по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) <a href="https://pd.rkn.gov.ru/operators-registry/operators-list/?id=36-18-002235"  target="_blank"> https://pd.rkn.gov.ru/operators-registry/operators-list/?id=36-18-002235</a>.
            </div>
            <div class="l-about__container-content-text">
              ООО ПКО "Юрисконсульт" внесено в реестр юридических лиц, осуществляющих деятельность по возврату просроченной задолженности. Свидетельство № 5/18/36000-КЛ от 14 марта 2018 года. Информация об этом доступна на сайте Федеральной службы судебных приставов (<a href="https://fssp.gov.ru/gosreestr_jurlic" target="_blank">https://fssp.gov.ru/gosreestr_jurlic</a>)
            </div>
            <div>
              <br>Реквизиты организации:<br>
              юридический адрес:<br>
              394054, Воронежская область, г. Воронеж, мкр. Жилой Массив Олимпийский, д. 17, кв. 924<br><br>
              ОГРН 1133668050317 свидетельство серия 36 № 003654515 от 19.11.2013 г.<br>
              выдано Межрайонной инспекцией Федеральной налоговой службы №1 по Воронежской области.<br>
              ИНН/КПП 3625013023/366501001
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AboutView',

    metaInfo() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'О компании',
        // all titles will be injected into this template
        // titleTemplate: '%s | Юрисконсульт',
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.description,
        }]
      }
    },

    data() {
      return {
        description: 'ООО ПКО "Юрисконсульт" внесено в реестр юридических лиц, осуществляющих деятельность по возврату просроченной задолженности. Организация предоставляет услуги по юридическому сопровождению банкротства физических и юридических лиц.',
      };
    },

    methods: {
      getDescription() {
        this.description = this.$axios.get()
        if (!this.description) {
          // if GET request failed or returned empty,
          // explicitly set to undefined so the parents'
          // default description is used
          this.description = undefined
        }
      }
    },
  }
</script>

<style scoped>
  .l-about__container-title {
    /* font-weight: bold; */
    font-family: "Roboto Bold";
    font-size: 24px; 
    text-align: left;
  }

  .l-about__container-content {
    font-family: "Times New Roman";
    font-size: 12px; 
    text-align: justify;
    /* padding: 5px 0; */
  }

  .l-about__container-content-text {
    padding: 5px 0;
  }

 .l-about .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-about .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }

  .l-about .line {
    padding-left: 20px;
    padding-right: 20px;
  }

    .l-about::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/взыскание 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (min-width: 992px) {
    .l-about__container {
      min-height: 650px;
    }

    .l-about__container-title {
      font-size: 36px; 
    }

    .l-about__container-content {
      font-size: 22px; 
    }
  }
</style>