<template>
  <div class="l-about container">
    <div class="row">
      <div class="l-about__container col">
        <h2 class="l-about__container-title">
          <a href="/about">О компании</a>
        </h2>  
        <div class="l-about__container-content">
          <div class="l-about__container-content-text">
            ООО ПКО «Юрисконсульт» внесено в реестр операторов персональных данных 02 февраля 2018 года, регистрационный номер 36-18-002235.
          </div>
          <div class="l-about__container-content-text">
            ООО ПКО "Юрисконсульт" внесено в реестр юридических лиц, осуществляющих деятельность по возврату просроченной задолженности. <br> Свидетельство №5/18/36000-КЛ от 14 марта 2018 года.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
  }
</script>

<style scoped>
  a {
    text-decoration: none;
    color: inherit;
    width: 100%;
  }

  .l-about__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .l-about__container-title {
    /* font-weight: bold;  */
    font-family: "Roboto Condensed";
    font-size: 24px;
    text-align: left;
    padding-left: 10px;
    
  }

  .l-about__container-content {
    font-family: "Times New Roman";
    font-size: 12px;
    /* text-align: justify; */
  }

  .l-about__container-content-text {
    padding: 10px 0;
  }

 .l-about .line:nth-child(2n) {
    background: rgb(226, 226, 226);
  }

  .l-about .line:nth-child(2n - 1) {
    background: rgb(238, 238, 238);
  }

  .l-about .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 992px) {
    .l-about__container-title {
      font-size: 45px;
      display: flex;
      /* width: min-content; */
      background: linear-gradient(269.91deg, #FEA41E 0%, #E6B567 0.01%, #6E4930 56.25%);
      color: white;
      /* min-width: 300px; */
      padding-right: 30px;
      line-height: 60px;
      justify-content: flex-end;
      margin-left: 240px;
    }

    .l-about__container-content {
      font-size: 28px;
      /* line-height: 42px; */
    }
  }  
</style>