<template>
  <div class="l-main" >
    <div class="container">
      <div class="row">
        <div class="l-main__container row">
            <ServiceInfo class="col" />
            <div class="img col"  v-if="isDesktop">
              <img src="@/assets/img/portrait-female-lawyer-in-formal-suit-with-clipboard 1.png" >
            </div>
        </div>
        <div class="l-main__container_two row">
          <div class="img col"  v-if="isDesktop">
            <img src="@/assets/img/main_img_02.jpg" height="425px" width="550px" >
          </div>
          <About class="col" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ServiceInfo from '@/components/MainView/ServiceInfo.vue';
  import About from '@/components/MainView/About.vue';

  export default {
    name: 'MainView',
    components: {
    ServiceInfo,
    About,
    },

    // metaInfo: {
    //   // if no subcomponents specify a metaInfo.title, this title will be used
    //   title: 'Главная',
    //   // all titles will be injected into this template
    //   // titleTemplate: '%s | Юрисконсульт',
    // },

    // metaInfo() {
    //   return {
    //     title: 'Главная',
    //     meta: [{
    //       vmid: 'description',
    //       name: 'description',
    //       content: this.description,
    //     }]
    //   }
    // },

    // data() {
    //   return {
    //     description: undefined
    //   }
    // },

    // methods: {
    //   getDescription() {
    //     this.description = this.$axios.get()
    //     if (!this.description) {
    //       // if GET request failed or returned empty,
    //       // explicitly set to undefined so the parents'
    //       // default description is used
    //       this.description = undefined
    //     }
    //   }
    // },
  }
</script>

<style scoped>
.l-main__container {
  width: 100%;
  position: relative;
}
  .l-main__container-content {
    min-height: 650px;
  }

  .l-main .line:nth-child(2n) {
    /* background: rgb(226, 226, 226); */
  }

  .l-main .line:nth-child(2n - 1) {
    /* background: rgb(238, 238, 238); */
  }

  .l-main .line {
    padding-left: 20px;
    padding-right: 20px;
  }

  .l-main__container .img {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .l-main__container_two .img {
    display: flex;
    align-items: center;
  }

  .l-main::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/close-up-of-workers-with-briefcases 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>
