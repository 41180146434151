<template>
  <div class="l-legal-ser__container">
    <h2 class="l-legal-ser__container-title">
      Банкротство юридических лиц
    </h2>
    <div class="l-legal-ser__container-info">
      в ООО ПКО «Юрисконсульт» реализуется посредством комплекса мероприятий по защите правовых и материальных интересов должника от ответственности, одновременной минимизации убытков и сохранению деловой репутации. Преимущества комплексного подхода выражаются в следующих аспектах:
      <ol>•	сохранение прав клиента на максимум активов;</ol>
      <ol>•	принятие мер по оперативному погашению дебиторской задолженности;</ol>
      <ol>•	реструктуризация управления;</ol>
      <ol>•	жесткий мониторинг финансовых потоков.</ol>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LegalBankruptcySer',
  }
</script>

<style scoped>
  .l-legal-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px;
    text-align: left;
  }

  .l-legal-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

     @media (min-width: 992px) {
         .l-legal-ser__container-title {
    font-size: 36px;
  }

  .l-legal-ser__container-info {
    font-size: 22px;
  }
     }
    
</style>