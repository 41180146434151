<template>
    <div class="l-contact container">
        <div class="row">
            <div class="l-contact__main row">
                <div class="l-contact__container col">
                    <div class="line">   
                        <h2 class="l-contact__container-title">
                            Контакты
                        </h2> 
                        <div class="l-contact__container-info">
                            <div class="l-contact__container-info-text">
                                <b>Телефон:</b> {{ number }}, {{ number2 }} <br>
                            </div>
                            <div class="l-contact__container-info-text">
                                <b>E-mail:</b> {{ email }} <br>
                            </div>
                            <div class="l-contact__container-info-text">
                                <b>Фактический адрес:</b><br>
                                {{ adress }}
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="l-contact__container col" v-if="isDesktop">
                            <!-- <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A345bc62b008cf67fc69344fd04d866cc5134c306046abaec45ad86cb4753f331&amp;source=constructor" width="562" height="472" frameborder="0"></iframe> -->
                            <!-- <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A345bc62b008cf67fc69344fd04d866cc5134c306046abaec45ad86cb4753f331&amp;source=constructor" width="562" height="472" frameborder="0"></iframe> -->
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A3670ebb235af2efcf9a9b9dbdf563167ab66a2ddf9ead184a733d2405d0e2c94&amp;source=constructor" width="500" height="400" frameborder="0"></iframe>
                </div>
                <div class="l-contact__container col" v-if="!isDesktop">
                            <!-- <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A345bc62b008cf67fc69344fd04d866cc5134c306046abaec45ad86cb4753f331&amp;source=constructor" width="562" height="472" frameborder="0"></iframe> -->
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A3670ebb235af2efcf9a9b9dbdf563167ab66a2ddf9ead184a733d2405d0e2c94&amp;source=constructor" width="320" height="240" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactsView',

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: 'Контакты',
                // all titles will be injected into this template
                // titleTemplate: '%s | Юрисконсульт',
                meta: [{
                vmid: 'description',
                name: 'description',
                content: this.description,
                }]
            }
        },

        data() {
            return {
                description: 'Доверьте дело профессионалам ООО ПКО "Юрисконсульт".',
                number: '8-960-127-50-08',
                number2: '8-960-132-70-03',
                email: 'oooyuriskonsult@mail.ru',
                adress: '394068, г. Воронеж, ул. Шишкова, д.118 ',
            };
        },

        methods: {
            getDescription() {
                this.description = this.$axios.get()
                if (!this.description) {
                // if GET request failed or returned empty,
                // explicitly set to undefined so the parents'
                // default description is used
                this.description = undefined
                }
            }
        },
    }
</script>

<style scoped>
    .l-contact__main {
        position: relative;
    }

    .l-contact__container {
        margin: 10px auto;
    }

    .l-contact__container-title {
        font-family: "Roboto Bold";
        font-size: 24px; 
        text-align: left;
    }

    .l-contact__container-info {
        font-family: "Times New Roman";
        font-size: 12px;
        text-align: justify;
    }

    .l-contact__container-info-text {
        padding: 15px 0;
    }

    .l-contact::before {
        content: "";
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0.5;
        background-image: url('../../assets/img/young-couple-checking-their-family-budget 1.png');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }

    @media (min-width: 992px) {
        .l-contact__container-title {
            font-size: 36px; 
        }

        .l-contact__container-info {
            font-size: 22px;
        }

        .l-contact__container {
            min-height: 650px;
        }
    }
</style>