import Vue from 'vue';
import Router from 'vue-router';
import MainView from '@/components/Views/MainView.vue';
import AboutView from '@/components/Views/AboutView.vue';
import ServiceView from '@/components/Views/ServiceView.vue';
import IndividualView from '@/components/Views/IndividualView.vue';
import LegalView from '@/components/Views/LegalView.vue';
import DebtsView from '@/components/Views/DebtsView.vue';
import LawServiceView from '@/components/Views/LawServiceView.vue';
import ContactsView from '@/components/Views/ContactsView.vue';
import PolitikView from '@/components/Views/PolitikView.vue';
import AgreementView from '@/components/Views/AgreementView.vue';
// import SalePropertyView from '@/components/Views/SalePropertyView.vue';


Vue.use(Router);


export default new Router({
    routes: [
        {
            path: '/',
            name: 'MainView',
            component: MainView,
        },
        {
            path: '/about',
            name: 'AboutView',
            component: AboutView,
        },
        {
            path: '/service',
            name: 'ServiceView',
            component: ServiceView,
        },
        {
            path: '/service/bankrotstvo-fizicheskih-lic',
            name: 'IndividualView',
            component: IndividualView,
        },
        {
            path: '/service/bankrotstvo-yuridicheskih-lic',
            name: 'LegalView',
            component: LegalView,
        },
        {
            path: '/service/vzyskanie-prosrochennoj-zadolzhennosti',
            name: 'DebtsView',
            component: DebtsView,
        },
        {
            path: '/service/yuridicheskie-uslugi',
            name: 'LawServiceView',
            component: LawServiceView,
        },
        {
            path: '/contacts',
            name: 'ContactsView',
            component: ContactsView,
        },
        {
            path: '/politik',
            name: 'PolitikView',
            component: PolitikView,
        },
        {
            path: '/agreement',
            name: 'AgreementView',
            component: AgreementView,
        },
    ],
    mode: 'history',
});