<template>
    <div class="l-politik container">
        <div class="row">
            <div class="l-politik__container col">
                <div class="line">   
                    <h2 class="l-politik__container-title">
                        ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2> 
                    <div class="l-politik__container-info">
                        <p><b>1. Общие положения</b></p>
                        <p>1.1. Политика в отношении обработки персональных данных (далее Политика) направлена на защиту прав и свобод физических лиц, персональные данные которых обрабатывает Общество с ограниченной ответственностью «Юрисконсульт» (далее Оператор).</p>
                        <p>1.2. Политика разработана в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» (далее ФЗ «О персональных данных»).</p>
                        <p>1.3. Настоящая Политика подлежит публикации на сайте Компании с целью обеспечения неограниченного доступа к документу.</p>
                        <p><b>2. Сведения об операторе</b></p>
                        <p>2.1. Оператор ведет свою деятельность по адресу:{{ adress }}</p>
                        <p><b>3. Сведения о собираемых данных</b></p>
                        <p>3.1. Персональные данные Фамилия, имя, отчество; адрес; номер контактного телефона; адрес электронной почты и другую информацию, содержащуюся в сообщениях, направляемых через формы обратной связи на сайте;</p>
                        <p>3.2. Технические данные, которые автоматически передаются устройством, в том числе технические характеристики устройства, IP-адрес, информация, сохраненная в файлах «cookies», которые были отправлены на Ваше устройство, информация о браузере, дата и время доступа к сайту, адреса запрашиваемых страниц и иная подобная информация;</p>
                        <p>3.3. Иные данные, которые оставляют посетители на сайте.</p>
                        <p><b>4. Сведения об обработке персональных данных</b></p>
                        <p>4.1. Оператор получает персональные данные непосредственно у субъектов персональных данных.</p>
                        <p>4.2. Оператор обрабатывает персональные данные автоматизированным и неавтоматизированным способами.</p>
                        <p>4.3. Действия по обработке персональных данных включают сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</p>
                        <p>4.4. Политика обязательна для ознакомления и исполнения руководителями структурных подразделений, работники которых принимают участие в обработке персональных данных.</p>
                        <p><b>5. Обработка персональных данных клиентов</b></p>
                        <p>5.1. Оператор обрабатывает персональные данные клиентов в целях соблюдения норм законодательства РФ, а также с целью:</p>
                        <p>
                            <ol>•	информировать о новых товарах, специальных акциях и предложениях;</ol>
                            <ol>•	заключение и исполнение условий договора.</ol>
                        </p>
                        <p>5.2. Оператор обрабатывает персональные данные клиентов с их согласия, предоставляемого клиентами и/или их законными представителями путем совершения конклюдентных действий на настоящем интернет-сайте.</p>
                        <p>5.3. Оператор обрабатывает персональные данные клиентов не дольше, чем того требуют цели обработки персональных данных, если иное не предусмотрено требованиями законодательства РФ.</p>
                        <p><b>6. Сведения об обеспечении безопасности персональных данных</b></p>
                        <p>6.1. Оператор назначает ответственного за организацию обработки персональных данных для выполнения обязанностей, предусмотренных ФЗ «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами.</p>
                        <p>6.2. Оператор применяет комплекс правовых, организационных и технических мер по обеспечению безопасности персональных данных для обеспечения конфиденциальности персональных данных и их защиты от неправомерных действий.</p>
                        <p><b>7. Права субъектов персональных данных</b></p>                        
                        <p>7.1. Субъект персональных данных имеет право:</p>
                        <p>
                            <ol>•	на получение персональных данных, относящихся к данному субъекту, и информации, касающейся их обработки;</ol>
                            <ol>•	на уточнение, блокирование или уничтожение его персональных данных в случае, если они являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</ol>
                            <ol>•	на отзыв данного им согласия на обработку персональных данных;</ol>
                            <ol>•	на защиту своих прав и законных интересов, в том числе на возмещение убытков и компенсацию морального вреда в судебном порядке;</ol>
                            <ol>•	на обжалование действий или бездействия Оператора в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</ol>
                        </p>
                        <p>7.2. Для реализации своих прав и законных интересов субъекты персональных данных имеют право обратиться к Оператору либо направить запрос лично или с помощью представителя. Запрос должен содержать сведения, указанные в ч. 3 ст. 14 ФЗ «О персональных данных».</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PolitikView',

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Политика обработки персональных данных',
            // all titles will be injected into this template
            // titleTemplate: '%s | Юрисконсульт',
        },

        data() {
            return {
                number: '8-960-127-50-08',
                // email: 'oooyuriskonsult@mail.ru',
                adress: '394068, г. Воронеж, ул. Шишкова, д.118 ',
            };
        },
    }
</script>

<style scoped>
.l-politik__container-title {
    font-family: "Roboto Bold";
    font-size: 36px; 
    text-align: left;
  }

  .l-politik__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

   .l-politik::before {
    content: "";
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    background-image: url('../../assets/img/close-up-of-workers-with-briefcases 1.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>