<template>
    <div class="l-footer container-fluid">
        <div class="row">
            <div class="l-footer __container container">
                <div class="l-footer __logo">
                    <div class="l-footer __logo_number">
                        
                    </div>
                    <div class="l-footer __logo_text">
                        © 2021 ООО ПКО Юрисконсульт. All rights reserved.
                    </div>
                    <div class="l-footer __text">
                        <a href="/politik">Политика конфиденциальности</a>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'Footer',
}

</script>

<style scoped>
    .l-footer__logo {
        height: 30px;
    }


    .l-footer__logo_number {
        text-align: right;
    }

    .l-footer__logo_text {
        text-align: center;
    }
</style>