import Vue from 'vue'
import App from './App.vue'
import Router from './router/index.js'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import DeviceTypeMixin from '@/utils/mixins/deviceTypeMixin.js'

Vue.config.productionTip = false

Vue.mixin(DeviceTypeMixin);

new Vue({
  router: Router,
  render: h => h(App),
}).$mount('#app')
