<template>
  <div class="l-individual-ser__container">
    <h2 class="l-individual-ser__container-title">
      Банкротство физических лиц
    </h2>
    <div class="l-individual-ser__container-info">
      <div>Законодательство Российской Федерации, регламентирующее несостоятельность (банкротство), находится в постоянном развитии и совершенствовании. Законом, регулирующим в настоящее время вопросы несостоятельности (банкротства), является Федеральный закон РФ от 26.10.2002 «О несостоятельности (банкротстве)» (далее - Закон о банкротстве).</div>
      <div>
        Инициировать процедуру банкротства физического лица может:
        <ol>•	гражданин;</ol>
        <ol>•	конкурсный кредитор;</ol>
        <ol>•	уполномоченный орган.</ol>
      </div>
      <div>
        Основные требования для введения процедуры банкротства физического лица:
        <ol>•	наличие долга по денежным обязательствам и (или) обязанности по уплате обязательных платежей на сумму не менее 500 000 рублей;</ol>
        <ol>•	неисполнение указанных обязательств более 3 месяцев;</ol>
        <ol>•	наличие объективных признаков неплатёжеспособности и (или) признаков недостаточности имущества.</ol>
      </div>
      <!-- <ol>- работа напрямую с Конкурсными Управляющими;</ol>
      <ol>- дисконт при оплате процедуры банкротства;</ol>
      <ol>- решение вопросов любой сложности: оспаривание сделок, исключение недвижимости из конкурсной массы;</ol>
      <ol>- предоставление рассрочки при оплате процедуры.</ol> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'IndividualBankruptcySer',
  }
</script>

<style scoped>
  .l-individual-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px;
    text-align: left;
  }

  .l-individual-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

     @media (min-width: 992px) {
       .l-individual-ser__container-title {
    font-size: 36px;
  }

  .l-individual-ser__container-info {
    font-size: 22px;
  }
     }
    
</style>