<template>
  <div class="l-debts-ser__container">
    <h2 class="l-debts-ser__container-title">
      Взыскание просроченной задолженности
    </h2>
    <div class="l-debts-ser__container-info">
      ООО ПКО «Юрисконсульт» с уважением относится ко всем своим клиентам, в том числе должникам. Наша цель - договориться о платежах на взаимовыгодной основе. ООО ПКО «Юрисконсульт» использует исключительно правовые методы и рассматривает все возможные варианты выхода должников из сложившейся ситуации:
      <ol>- дисконт при полной оплате суммы долга, рассрочки оплаты долга, </ol>
      <ol>- заключение мирового соглашения с дисконтом до 50% , т.д. (Не является публичной офертой. Условия дисконтов и мировых соглашений обговариваются с должниками индивидуально.)</ol>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DebtsSer',
  }
</script>

<style scoped>
  .l-debts-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px;
    text-align: left;
  }

  .l-debts-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

     @media (min-width: 992px) {
         .l-debts-ser__container-title {
    font-size: 36px;
  }

  .l-debts-ser__container-info {
    font-size: 22px;
  }
     }
    
</style>