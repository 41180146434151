<template>
  <div class="l-law-ser__container col">
    <div class="line">
      <h2 class="l-law-ser__container-title">
          Юридические услуги
      </h2>
      <div class="l-law-ser__container-info">
        УСЛУГИ ЮРИДИЧЕСКОГО СОПРОВОЖДЕНИЯ В АРБИТРАЖНОМ СУДЕ <br>
        Чтобы эффективно и оперативно решить споры, возникшие в процессе ведения бизнеса, необходима квалифицированная юридическая помощь. Специалисты ООО ПКО «Юрисконсульт» помогут урегулировать разногласия, возникающие в сфере предпринимательской и иной экономической деятельности, а также защитят ваши интересы в суде. Мы готовы предоставить вам квалифицированные услуги независимо от того, являетесь вы истцом или ответчиком.
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LawService',
  }
</script>

<style scoped>
  .l-law-ser__container-title {
    font-family: "Roboto Bold";
    font-size: 24px;
    text-align: left;
  }

  .l-law-ser__container-info {
    font-family: "Times New Roman";
    font-size: 12px;
    text-align: justify;
  }

    @media (min-width: 992px) {
    .l-law-ser__container-title {
        font-size: 36px;
    }

    .l-law-ser__container-info {
      font-size: 22px;
    }
    }
    
</style>