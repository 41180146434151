<template>
    <div class="l-menu">
        <div class="l-menu__icon" v-if="!isDesktop" @click="menuIconClickHandler">
            <svg fill="#6E4930" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="48px" height="48px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
        </div>
        <div class="l-menu__list" v-if="isMenuOpen || isDesktop">
            <DropdownMenuItem class="l-menu__item" v-for="(item, i) in menu" :item="item" :mode="isDesktop ? 'hover' : 'click'" :key="`item-${i}`" @menuClickHandlers="menuClickHandler" :activeMenu="activeMenu" />
            <!-- <DropdownMenuItem class="l-menu__item" v-for="(item, i) in menu" :item="item" :mode="click" :key="`item-${i}`" /> -->
        </div>
    </div>
</template>

<script>
import MenuData from '@/utils/menu.json';
import DropdownMenuItem from '@/components/ui/DropdownMenuItem.vue';

export default {
    name: 'Menu',

    components: {
      DropdownMenuItem,
    },

    data() {
      return {
        menu: MenuData,
        isMenuOpen: false,
        activeMenu: "",
      };
    },

    methods: {
        menuIconClickHandler() {
            this.isMenuOpen = !this.isMenuOpen; 
        },

        menuClickHandler(item) {
            console.log("item");
            console.log(item);
            this.activeMenu = item;
        },
    },
  }
</script>

<style scoped>
    .l-menu__list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        background: linear-gradient(269.91deg, #FEA41E 0%, #E6B567 0.01%, #6E4930 56.25%);
    }

    .l-menu__item {
        height: 100%;   
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .l-menu__icon {
        position: relative;
        top: 0px;
    }

    @media (min-width: 992px) {
        .l-menu {
            /* height: 40px; */
            display: flex;
            align-items: center;
        }

        .l-menu__list {
            align-items: center;
            flex-direction: row;
            height: 100%;
        }

        .l-menu__item {
            height: 100%;   
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
</style>